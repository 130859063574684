import classNames from "classnames";
import { Shape } from "../../../components/shapes";
import { Grid } from "../../blocs";

import { HTMLAttributes } from "react";
import { computeIconStyle, IconStyleType } from "..";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { IconButtonIcons, IconList } from "../../icons";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: any;
  size?: number;
  icon?: IconList;
  iconColor?: CssVariablesType;
  shapeColor?: CssVariablesType;
  iconStyle?: IconStyleType;
  marginTop?: boolean;
  styleText?: any;
};

export function H4({ children, size = 14, icon, iconColor = "text-soft", shapeColor = "blue-background", styleText, iconStyle = null, className, ...props }: Props) {
  let computed = computeIconStyle(iconStyle);
  const computedShapeColor = computed?.shapeColor ?? shapeColor;
  const computedIconColor = computed?.iconColor ?? iconColor;

  return (
    <Grid space={10} mediaQuery={0} template={icon ? `auto 1fr` : "1fr"} alignItems="center" className={classNames("mb-1 ", className)} {...props}>
      {icon && (
        <Shape form="circle" color={computedShapeColor}>
          <IconButtonIcons icon={icon} size={size} color={computedIconColor} />
        </Shape>
      )}
      {children && (
        <h4 className="mt-0 mb-0" style={styleText}>
          {children}
        </h4>
      )}
    </Grid>
  );
}
